export const ADELANTADO = 1;
export const REZAGADO = 2;

const _nombres = Object.freeze({
  1: "Adelantado",
  2: "Rezagado",
});

export const combo = Object.freeze({
  [ADELANTADO]: {
    text: "Adelantado",
    value: 1,
  },
  [REZAGADO]: {
    text: "Rezagado",
    value: 2,
  },
});

export function nombre(k) {
  return _nombres[k];
}

export function esAdelantado(n) {
  return n == ADELANTADO;
}

export function esRezagado(n) {
  return n == REZAGADO;
}
