<template>
  <div class="listado tabla-mobile">
    <filtros-listado-mobile>
      <filtro-activo field="activo" />
    </filtros-listado-mobile>
    <fila-compacta v-for="(indicador, idx) in indicadores" v-bind:key="idx" :titulo="indicador.nombre">
      <div>
        {{ $t("Naturaleza") }}: <span>{{ getNaturalezaStr(indicador.naturaleza) }}</span
        ><br />
        {{ $t("Origen datos") }}: <span v-if="indicador.tipoIndicador == 1">Manual</span
        ><span v-else>{{ getOrigenStr(indicador.origenDatosId) }}</span
        ><br />
        {{ $t("Con/Sin datos") }}:
        <span v-if="indicador.conDatos">Con datos</span>
        <span v-else indicador.conDatos>Sin datos</span>
        <br />
        {{ $t("Estado") }}:
        <span v-if="indicador.activo == true">Activo</span>
        <span v-else>Inactivo</span>
      </div>

      <botonera-acciones-tabla
        slot="toolbar"
        :indicador="indicador"
        @indicadorEliminado="onIndicadorEliminado"
        @importarDatasource="importarDatasource(indicador)"
      />
    </fila-compacta>

    <h3 class="text-center" v-if="indicadores.length == 0">
      {{ $t("No hay orígenes de datos") }}
    </h3>
  </div>
</template>

<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
import * as OrigenIndicador from "@/enum/OrigenIndicador";
import * as NaturalezaIndicador from "@/enum/NaturalezaIndicador";
export default {
  props: {
    indicadores: { type: Array, required: true },
  },
  data() {
    return {
      activo: 1,
    };
  },
  components: { BotoneraAccionesTabla },
  methods: {
    importarDatasource(id) {
      this.$emit("importarDatasource", id);
    },
    getNaturalezaStr(naturaleza) {
      return NaturalezaIndicador.nombre(naturaleza);
    },
    getOrigenStr(origenDatosId) {
      return OrigenIndicador.nombre(origenDatosId);
    },
    onIndicadorEliminado(id) {
      this.$emit("indicadorEliminado", id);
    },
  },
};
</script>
