<template>
  <page-listado title="Indicadores" con-busqueda>
    <filtro-activo field="activo" class="float-left mr-3 d-md-block d-none" />

    <template slot="toolbar">
      <b-button variant="primary" size="sm" to="/administracion/indicadores/crear">
        <v-icon name="plus" width="16"></v-icon>
        {{ $t("Crear") }}
      </b-button>
    </template>

    <tabla
      :indicadores="resultado.indicadores"
      @indicadorEliminado="indicadorEliminado"
      @importarDatasource="importarDatasource"
    ></tabla>

    <paginacion
      v-if="resultado.numeroPaginas > 1"
      :page-count="resultado.numeroPaginas"
      :page="resultado.pagina"
      :pageRange="3"
    ></paginacion>
  </page-listado>
</template>

<script>
import * as apiIndicadores from "@/services/indicadores.js";
import * as apiOrigenesDatos from "@/services/origenesDatos";
import Tabla from "./Tabla.vue";
export default {
  components: { Tabla },
  data() {
    return {
      resultado: {
        indicadores: [],
        numeroPaginas: 0,
        pagina: 1,
      },
    };
  },
  methods: {
    async listar() {
      this.resultado = await apiIndicadores.paginar(this.$route.query);
    },
    async importarDatasource(indicador) {
      let medicionesCreadas = await apiIndicadores.importarDatasource(indicador);
      if (medicionesCreadas) {
        this.$emit("userMessage", "Se han importado las mediciones");
        this.$router.push({ path: "indicadores/" + indicador.id + "/mediciones" });
      }
    },
    indicadorEliminado(id) {
      this.listar();
    },
  },
  watch: {
    "$route.params": {
      handler: function () {
        this.listar();
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
