import { listado } from "./listado";
import { esAdelantado } from "@/enum/NaturalezaIndicador.js";
import { api } from "./backend";

export function crear() {
  return {
    id: 0,
    nombre: "",
    tipoIndicador: 1,
    origenDatosId: 0,
    naturaleza: 1,
    activo: 1,
  };
}

export async function listar(params) {
  const response = await api.get("indicador/listar", params);
  return response.data;
}

export async function paginar(params) {
  return await listado("indicador/paginar", params, { activo: 1 });
}

export async function listarAdelantados(params) {
  return await listado("indicador/listarAdelantados", params);
}

export async function cargar(id) {
  const response = await api.get("indicador/cargar", { id });
  return response.data;
}

export async function cargarConRelaciones(id) {
  const response = await api.get("indicador/cargarConRelaciones", { id });
  return response.data;
}

export async function insertar(indicador, relacionados) {
  const response = await api.post("indicador/insertar", { indicador, relacionados });
  return response.data;
}

export async function actualizar(indicador, relacionados) {
  if (esAdelantado(indicador.naturaleza)) {
    relacionados = [];
  }
  const response = await api.post("indicador/actualizar", { indicador, relacionados });
  return response.data;
}

export async function eliminar(id) {
  await api.post("indicador/eliminar", { id });
}

export async function importar(id, fichero, reemplazar) {
  await api.post("indicador/importar", { id, fichero, reemplazar });
}

export async function borrarMediciones(id) {
  await api.post("indicador/borrarMediciones", { id });
}

export async function importarDatasource(indicador) {
  const response = await api.post("indicador/importarDatasource", { indicador });
  return response.data;
}

export async function cargarUltimaFecha(id){
  const response = await api.get("indicador/cargarUltimaFecha", {id});
  return response.data;
}