<template>
  <acciones>
    <accion-primaria :label="$t('Editar')" icon="edit" :to="`/administracion/indicadores/editar/${indicador.id}`" />

    <acciones-secundarias>
      <accion-eliminar
        @eliminar="eliminarIndicador(indicador)"
        :confirmacion="`¿Desea eliminar el indicador ${indicador.nombre}?`"
      />
      <b-dropdown-divider></b-dropdown-divider>
      <accion-secundaria
        v-if="indicador.activo"
        :label="$t('Mediciones')"
        icon="bar-chart-2"
        :to="`indicadores/${indicador.id}/mediciones`"
      ></accion-secundaria>
      <accion-secundaria
        v-if="indicador.tipoIndicador == 2"
        :label="$t('Actualizar datos')"
        icon="refresh-cw"
        @click="importarDatasource"
      ></accion-secundaria>
      <accion-secundaria
        :label="$t('Importar Mediciones')"
        icon="upload"
        :to="`indicadores/${indicador.id}/mediciones/importar`"
      ></accion-secundaria>
      <accion-secundaria label="Borrar mediciones" icon="x-circle" @click="borrarMediciones(indicador)" />
    </acciones-secundarias>
  </acciones>
</template>
<script>
import * as apiIndicadores from "@/services/indicadores.js";
export default {
  props: {
    indicador: Object,
  },
  methods: {
    // isTipoDatasource() {
    //   console.log(this.indicador.tipoIndicador);
    //   // Se obtiene el tipo de origen de datos
    //   if (this.indicador.tipoIndicador == 2) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // Lista las mediciones que pertenecen a los indicadores de tipo checklist
    importarDatasource(indicador) {
      this.$emit("importarDatasource", indicador.id);
    },
    async eliminarIndicador(indicador) {
      await apiIndicadores.eliminar(indicador.id);
      this.$root.$emit("userMessage", this.$t("Indicador eliminado"));
      this.$emit("indicadorEliminado", indicador.id);
    },
    async borrarMediciones(indicador) {
      const ok = await this.$bvModal.msgBoxConfirm("¿Desea eliminar todas las mediciones de este indicador?");
      if (ok) {
        await apiIndicadores.borrarMediciones(indicador.id);
        this.$root.$emit("userMessage", this.$t("Mediciones eliminadas"));
        this.$emit("indicadorEliminada", indicador.id);
      }
    },
  },
};
</script>
