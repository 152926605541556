<template>
  <table class="listado table table-hover m-0 tabla-desktop">
    <thead class="bg-primary text-white">
      <th>{{ $t("Nombre") }}</th>
      <th>{{ $t("Naturaleza") }}</th>
      <th>{{ $t("Origen datos") }}</th>
      <th class="text-center">{{ $t("Mediciones") }}</th>
      <th class="th-acciones">{{ $t("Acciones") }}</th>
    </thead>
    <tbody>
      <tr v-for="indicador in indicadores" v-bind:key="indicador.id">
        <td>{{ indicador.nombre }}</td>
        <td>{{ nombreNaturaleza(indicador.naturaleza) }}</td>
        <td>{{ indicador.nombreOrigen }}</td>
        <td class="text-center">{{ indicador.totalMediciones }}</td>
        <td nowrap="nowrap" class="td-acciones">
          <botonera-acciones-tabla
            :indicador="indicador"
            @indicadorEliminado="onIndicadorEliminado"
            @importarDatasource="importarDatasource(indicador)"
          />
        </td>
      </tr>
      <sin-registros :count="indicadores.length" :colspan="6"></sin-registros>
    </tbody>
  </table>
</template>
<script>
import BotoneraAccionesTabla from "./BotoneraAccionesTabla.vue";
import * as OrigenIndicador from "@/enum/OrigenIndicador";
import * as NaturalezaIndicador from "@/enum/NaturalezaIndicador";
export default {
  props: {
    indicadores: Array,
  },
  methods: {
    importarDatasource(id) {
      this.$emit("importarDatasource", id);
    },
    nombreNaturaleza(naturaleza) {
      return NaturalezaIndicador.nombre(naturaleza);
    },
    onIndicadorEliminado(id) {
      this.$emit("indicadorEliminado", id);
    },
  },
  components: {
    BotoneraAccionesTabla,
  },
};
</script>
