import { listado } from "./listado";
import { api } from "./backend";

export function crear() {
  return {
    id: 0,
    tipoOrigen: "",
    nombre: "",
    endpoint: "",
    observaciones: "",
    activo: "1",
  };
}

export async function listar(params) {
  return await listado("origenDatos/listar", params, { activo: 1 });
}

export async function listarChecklist(params) {
  return await listado("origenDatos/listarChecklist", params);
}

export async function listarIncidentes(params) {
  return await listado("origenDatos/listarIncidentes", params);
}

export async function cargar(id) {
  const response = await api.get("origenDatos/cargar", { id });
  return response.data;
}

export async function insertar(origenDatos) {
  const response = await api.post("origenDatos/insertar", { origenDatos });
  return response.data;
}

export async function actualizar(origenDatos) {
  const response = await api.post("origenDatos/actualizar", { origenDatos });
  return response.data;
}

export async function eliminar(id) {
  await api.post("origenDatos/eliminar", { id });
}
