export const CHECKLIST = 1;
export const INCIDENTES = 2;

const _nombres = Object.freeze({
  1: "Incidentes",
  2: "CheckList",
});

export const combo = Object.freeze({
  [INCIDENTES]: {
    text: "Incidentes",
    value: 1,
  },
  [CHECKLIST]: {
    text: "CheckList",
    value: 2,
  },
});

export function nombre(origen) {
  return _nombres[origen];
}
