<template>
  <div>
    <tabla-mobile
      :indicadores="indicadores"
      @indicadorEliminado="onIndicadorEliminado"
      @importarDatasource="importarDatasource"
    />
    <tabla-desktop
      :indicadores="indicadores"
      @indicadorEliminado="onIndicadorEliminado"
      @importarDatasource="importarDatasource"
    />
  </div>
</template>
<script>
import TablaMobile from "./TablaMobile.vue";
import TablaDesktop from "./TablaDesktop.vue";
export default {
  components: {
    TablaMobile,
    TablaDesktop,
  },
  props: {
    indicadores: Array,
  },
  methods: {
    importarDatasource(id) {
      this.$emit("importarDatasource", id);
    },
    onIndicadorEliminado(id) {
      this.$emit("indicadorEliminado", id);
    },
  },
};
</script>
